import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

function NightCloudy (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <path d="M4.14 16.9c0-1.16.35-2.18 1.06-3.08s1.62-1.47 2.74-1.72c.23-1.03.7-1.93 1.4-2.7a5.93 5.93 0 012.53-1.65c.62-.21 1.26-.32 1.93-.32.81 0 1.6.16 2.35.48.28-.47.61-.88.99-1.22.38-.34.77-.61 1.17-.79.4-.18.8-.32 1.18-.41s.76-.13 1.12-.13c.38 0 .79.05 1.23.16l.82.25c.14.06.18.13.14.22l-.14.6c-.07.31-.1.6-.1.86 0 .31.05.63.15.95.1.32.24.63.44.94.19.31.46.58.8.83s.72.44 1.15.57l.62.22c.1.03.15.08.15.16 0 .02-.01.04-.02.07l-.18.67c-.27 1.08-.78 1.93-1.5 2.57.4.7.62 1.45.65 2.24.01.05.01.12.01.23 0 .89-.22 1.72-.67 2.48-.44.76-1.05 1.36-1.8 1.8-.76.44-1.59.67-2.48.67H9.07c-.89 0-1.72-.22-2.48-.67s-1.35-1.05-1.79-1.8-.66-1.58-.66-2.48zm1.71 0c0 .89.32 1.66.96 2.31.64.65 1.39.98 2.26.98h10.81c.89 0 1.65-.32 2.28-.97s.95-1.42.95-2.32c0-.88-.32-1.63-.96-2.26-.64-.63-1.4-.95-2.28-.95h-1.78l-.1-.75c-.1-1.01-.52-1.88-1.26-2.59s-1.62-1.11-2.63-1.2c-.03 0-.08 0-.15-.01-.07-.01-.11-.01-.15-.01-.51 0-1.02.1-1.54.29V9.4c-.73.28-1.35.74-1.84 1.37-.5.63-.8 1.35-.9 2.17l-.07.72-.68.03c-.84.1-1.54.45-2.1 1.06s-.82 1.32-.82 2.15zM17.6 8.79c1.06.91 1.72 1.97 1.97 3.18h.32c1.24 0 2.3.39 3.17 1.18.33-.31.58-.67.76-1.07a4.95 4.95 0 01-2.16-1.97c-.52-.88-.79-1.81-.79-2.78v-.24c-.05-.01-.13-.01-.24-.01-.58-.01-1.15.13-1.7.44-.55.3-1 .72-1.33 1.27z"/>
    </SvgIcon>
  )
}

export default NightCloudy
