import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import WalkingIcon from '../Icons/Walking'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    alignSelf: 'start'
  }
}))

const Steps = props => {
  const classes = useStyles()

  if (props.value == null) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Steps
        </Typography>
        <Tooltip title={`${props.value.toFixed(0)} steps`} enterDelay={300} arrow placement="top">
          <div>
            <WalkingIcon fontSize="large" />
          </div>
        </Tooltip>
        <Typography variant="h6">
          {props.value.toFixed(0)}
        </Typography>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          Today
        </Typography>
      </CardContent>
    </Card>
  )
}
Steps.propTypes = {
  value: PropTypes.number
}

export default Steps
