import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const SignIn = props => {
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      history.replace('/')
    }, 2000)
  }, [history])

  return (
    <div>
      <h1>SignIn</h1>
    </div>
  )
}

export default SignIn
