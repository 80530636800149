import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import CloudIcon from '../Icons/Cloud'
import CloudyIcon from '../Icons/Cloudy'
import DayClearIcon from '../Icons/DayClear'
import DayCloudyIcon from '../Icons/DayCloudy'
import DayFogIcon from '../Icons/DayFog'
import DayRainIcon from '../Icons/DayRain'
import DayShowersIcon from '../Icons/DayShowers'
import DaySnowIcon from '../Icons/DaySnow'
import DayThunderstormIcon from '../Icons/DayThunderstorm'
import NightClearIcon from '../Icons/NightClear'
import NightCloudyIcon from '../Icons/NightCloudy'
import NightFogIcon from '../Icons/NightFog'
import NightRainIcon from '../Icons/NightRain'
import NightShowersIcon from '../Icons/NightShowers'
import NightSnowIcon from '../Icons/NightSnow'
import NightThunderstormIcon from '../Icons/NightThunderstorm'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    alignSelf: 'start'
  },
  icon: {
    fontSize: 80,
    transform: 'translateX(0%)',
    animation: '$slide 4s infinite alternate linear'
  },
  '@keyframes slide': {
    '0%': {
      transform: 'translateY(8%)'
    },
    '100%': {
      transform: 'translateY(-8%)'
    }
  },
  temperatures: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5)
    }
  }
}))

const icons = {
  '01d': DayClearIcon,
  '01n': NightClearIcon,
  '02d': DayCloudyIcon,
  '02n': NightCloudyIcon,
  '03d': CloudIcon,
  '03n': CloudIcon,
  '04d': CloudyIcon,
  '04n': CloudyIcon,
  '09d': DayShowersIcon,
  '09n': NightShowersIcon,
  '10d': DayRainIcon,
  '10n': NightRainIcon,
  '11d': DayThunderstormIcon,
  '11n': NightThunderstormIcon,
  '13d': DaySnowIcon,
  '13n': NightSnowIcon,
  '50d': DayFogIcon,
  '50n': NightFogIcon
}

const Weather = props => {
  const classes = useStyles()

  if (Object.values(props).some(value => value == null)) {
    return (
      <LoadingCard />
    )
  }

  const Icon = icons[props.icon]

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Weather
        </Typography>
        { Icon && (
          <Tooltip title={props.label.charAt(0).toUpperCase() + props.label.slice(1)} enterDelay={300} arrow placement="top">
            <div>
              <Icon className={classes.icon} />
            </div>
          </Tooltip>
        )}
        <div className={classes.temperatures}>
          <Tooltip title={`Temperature: ${props.temperature.toFixed(1)}°C`} enterDelay={300} arrow placement="bottom-start">
            <Typography variant="subtitle1" >
              {props.temperature.toFixed(1)}°C
            </Typography>
          </Tooltip>
          <Tooltip title={`Feels like: ${props.feelsLike.toFixed(1)}°C`} enterDelay={300} arrow placement="bottom-end">
            <Typography variant="subtitle1" color="textSecondary">
              {props.feelsLike.toFixed(1)}°C
            </Typography>
          </Tooltip>
        </div>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
Weather.propTypes = {
  time: PropTypes.instanceOf(Date),
  code: PropTypes.number,
  icon: PropTypes.string,
  label: PropTypes.string,
  temperature: PropTypes.number,
  feelsLike: PropTypes.number
}

export default Weather
