import { CardContent, Link, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import GithubIcon from '../Icons/Github'
import InstagramIcon from '../Icons/Instagram'
import LinkedInIcon from '../Icons/LinkedIn'
import TwitterIcon from '../Icons/Twitter'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  details: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  icon: {
    margin: theme.spacing(0, 1),
    fontSize: 25
  }
}))

const SocialMedia = props => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Social Media
        </Typography>
        <div className={classes.details}>
          <Link className={classes.line} href="https://twitter.com/Bakeneko_chan" target="_blank" rel="noopener" color="inherit" underline="hover">
            <TwitterIcon className={classes.icon} />
            <Typography variant="body2">
              Bakeneko_chan
            </Typography>
          </Link>
          <Link className={classes.line} href="https://github.com/Bakeneko" target="_blank" rel="noopener" color="inherit" underline="hover">
            <GithubIcon className={classes.icon} />
            <Typography variant="body2">
              Bakeneko
            </Typography>
          </Link>
          <Link className={classes.line} href="https://www.linkedin.com/in/bakeneko" target="_blank" rel="noopener" color="inherit" underline="hover">
            <LinkedInIcon className={classes.icon} />
            <Typography variant="body2">
              Bakeneko
            </Typography>
          </Link>
          <Link className={classes.line} href="https://www.instagram.com/bakeneko_sama" target="_blank" rel="noopener" color="inherit" underline="hover">
            <InstagramIcon className={classes.icon} />
            <Typography variant="body2">
              Bakeneko_sama
            </Typography>
          </Link>
        </div>
      </CardContent>
    </Card>
  )
}

export default SocialMedia
