import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import WeightIcon from '../Icons/Weight'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    alignSelf: 'start'
  }
}))

const Weight = props => {
  const classes = useStyles()

  if (props.time == null || props.value == null) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Weight
        </Typography>
        <Tooltip title={`Weight: ${props.value.toFixed(1)} Kg`} enterDelay={300} arrow placement="top">
          <div>
            <WeightIcon fontSize="large" />
          </div>
        </Tooltip>
        <Typography variant="h6">
          {props.value.toFixed(1)} Kg
        </Typography>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
Weight.propTypes = {
  time: PropTypes.instanceOf(Date),
  value: PropTypes.number
}

export default Weight
