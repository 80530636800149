import { CardContent, CardMedia, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    position: 'absolute',
    top: 4,
    left: 4,
    width: 24,
    backgroundColor: '#ffffff',
    padding: 2,
    borderRadius: 50
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  }
}))

const MediaLibraryHistory = props => {
  const classes = useStyles()

  if (props.time == null || props.title == null || props.thumbnail == null) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={props.thumbnail}
        title={`Last watched: ${props.title}${props.subtitle != null ? ' - ' + props.subtitle : ''}`}
      />
      <img className={classes.icon} src="/images/jellyfin_logo.svg" alt="Jellyfin" />
      <CardContent className={classes.cardContent}>
        <Typography variant="body2">
          {props.title}
        </Typography>
        {props.subtitle != null && (
          <Typography variant="caption">
            {props.subtitle}
          </Typography>
        )}
        <Typography variant="overline" style={{ marginTop: 'auto', fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
MediaLibraryHistory.propTypes = {
  time: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  thumbnail: PropTypes.string
}

export default MediaLibraryHistory
