import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import GithubIcon from '../Icons/Github'
import InstagramIcon from '../Icons/Instagram'
import LinkedInIcon from '../Icons/LinkedIn'
import TwitterIcon from '../Icons/Twitter'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  icon: {
    margin: theme.spacing(0, 1),
    fontSize: theme.typography.pxToRem(28)
  }
}))

const ContactDialog = props => {
  const { open, onClose } = props
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="contact-dialog-title">
      <DialogTitle id="contact-dialog-title">Contact</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          Reach me at <Link href="mailto:aleistor@gmail.com" color="textPrimary" underline="hover">aleistor@gmail.com</Link> and on social media.
        </DialogContentText>
        <div>
          <Link className={classes.line} href="https://twitter.com/Bakeneko_chan" target="_blank" rel="noopener" color="inherit" underline="hover">
            <TwitterIcon className={classes.icon} />
            <Typography variant="body1">
              Bakeneko_chan
            </Typography>
          </Link>
          <Link className={classes.line} href="https://github.com/Bakeneko" target="_blank" rel="noopener" color="inherit" underline="hover">
            <GithubIcon className={classes.icon} />
            <Typography variant="body1">
              Bakeneko
            </Typography>
          </Link>
          <Link className={classes.line} href="https://www.linkedin.com/in/bakeneko" target="_blank" rel="noopener" color="inherit" underline="hover">
            <LinkedInIcon className={classes.icon} />
            <Typography variant="body1">
              Bakeneko
            </Typography>
          </Link>
          <Link className={classes.line} href="https://www.instagram.com/bakeneko_sama" target="_blank" rel="noopener" color="inherit" underline="hover">
            <InstagramIcon className={classes.icon} />
            <Typography variant="body1">
              Bakeneko_sama
            </Typography>
          </Link>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContactDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
}

export default ContactDialog
