import { CardContent, CardMedia, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  icon: {
    position: 'absolute',
    top: 4,
    left: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  }
}))

const Books = props => {
  const classes = useStyles()

  if (props.time == null || props.title == null) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      {props.thumbnail && (
        <CardMedia
          className={classes.cardMedia}
          image={props.thumbnail}
          title={`${props.title}${props.subtitle != null ? ' - ' + props.subtitle : ''}`}
        />
      )}
      <BookmarkIcon className={classes.icon} />
      <CardContent className={classes.cardContent}>
        <Typography variant="body2">
          {props.title}
        </Typography>
        {props.subtitle != null && (
          <Typography variant="caption">
            {props.subtitle}
          </Typography>
        )}
        {props.authors?.length > 0 && (
          <Typography variant="caption" color="textSecondary">
            {props.authors[0]}
          </Typography>
        )}
        {(props.position ?? 0) > 0 && (
          <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
            {`Page ${props.position}${(props.pages ?? 0) > 0 ? ` / ${props.pages}` : ''}`}
          </Typography>
        )}
        <Typography variant="overline" style={{ marginTop: 'auto', fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
Books.propTypes = {
  time: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  thumbnail: PropTypes.string,
  authors: PropTypes.arrayOf(PropTypes.string),
  position: PropTypes.number,
  pages: PropTypes.number
}

export default Books
