import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    alignSelf: 'start'
  },
  valueContainer: {
    position: 'relative',
    width: 80,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  pulse: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: theme.palette.primary.main,
    borderRadius: '50%',
    transform: 'scale(1)',
    animationName: '$pulse',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationDuration: props => `${props.animationDuration}s`
  },
  rate: {
    position: 'absolute',
    textAlign: 'center'
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.8)'
    },
    '70%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(0.95)'
    }
  }
}))

const HeartRate = props => {
  const classes = useStyles({ animationDuration: 60 / (props?.value ?? 80) / 2 })

  if (props.time == null || props.value == null) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Heart Rate
        </Typography>
        <Tooltip title={`${props.value.toFixed(0)} BPM`} enterDelay={300} arrow placement="top">
          <div className={classes.valueContainer}>
            <div className={classes.pulse} />
            <Typography className={classes.rate} variant="h5">
              {props.value.toFixed(0)}
            </Typography>
          </div>
        </Tooltip>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
HeartRate.propTypes = {
  time: PropTypes.instanceOf(Date),
  value: PropTypes.number
}

export default HeartRate
