import PropTypes from 'prop-types'
import React, { createContext, useContext, useState } from 'react'

import Firebase from './firebase'

const FirebaseContext = createContext()

export const FirebaseContextProvider = props => {
  const [firebase] = useState(new Firebase())

  return (
    <FirebaseContext.Provider
      value={firebase}
    >
      {props.children}
    </FirebaseContext.Provider>
  )
}

FirebaseContextProvider.propTypes = {
  children: PropTypes.node
}

export const useFirebase = () => {
  return useContext(FirebaseContext)
}

export default FirebaseContext
