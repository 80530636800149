import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import MailIcon from '@material-ui/icons/Mail'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    alignSelf: 'start'
  },
  icon: {
    fontSize: theme.typography.pxToRem(36),
    transform: 'translateX(0)',
    opacity: 1,
    animation: '$slide 3s infinite ease-out'
  },
  '@keyframes slide': {
    '0%': {
      transform: 'translateX(-75%)',
      opacity: 0
    },
    '50%': {
      opacity: 1
    },
    '100%': {
      transform: 'translateX(75%)',
      opacity: 0
    }
  }
}))

const Mails = props => {
  const classes = useStyles()

  if (Object.values(props).some(value => value == null)) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Mails
        </Typography>
        <Tooltip title={`${props.recentMailCount} new mails`} enterDelay={300} arrow placement="top">
          <div>
            <MailIcon className={classes.icon} />
          </div>
        </Tooltip>
        <Typography variant="subtitle2">
          {props.recentMailCount} new
        </Typography>
        <Typography variant="subtitle2">
          {props.recentAndReadMailCount} read
        </Typography>
        <Typography variant="subtitle2">
          {props.unreadMailCount} unread
        </Typography>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
Mails.propTypes = {
  time: PropTypes.instanceOf(Date),
  recentMailCount: PropTypes.number,
  recentAndReadMailCount: PropTypes.number,
  unreadMailCount: PropTypes.number
}

export default Mails
