import { IconButton, Link, Slide, Tooltip, useScrollTrigger } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import { amber, blue, grey, red } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import EmailIcon from '@material-ui/icons/Email'
import PersonIcon from '@material-ui/icons/Person'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Link as RouterLink, Route, Switch } from 'react-router-dom'

import * as ROUTES from '../../constants/routes'
import AdminPage from '../Admin'
import ContactDialog from '../ContactDialog'
import { useFirebase } from '../Firebase'
import HomePage from '../Home'
import ProfilePage from '../Profile/Profile'
import SignInPage from '../SignIn'

const HideOnScroll = props => {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}
HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  grow: {
    flexGrow: 1
  }
}))

const App = props => {
  const firebase = useFirebase()
  const classes = useStyles()
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)', { noSsr: true })
  const [paletteType, setPaletteType] = useState(prefersLightMode ? 'light' : 'dark')
  const theme = React.useMemo(() => {
    const nextTheme = createTheme({
      overrides: {
        MuiCardContent: {
          root: {
            '&:last-child': {
              paddingBottom: 16
            }
          }
        }
      },
      palette: {
        type: paletteType,
        primary: {
          main: blue.A400
        },
        secondary: {
          main: amber.A400
        },
        error: {
          main: red.A400
        },
        background: paletteType === 'light'
          ? {
              default: grey[300],
              paper: grey[200]
            }
          : {}
      }
    })
    return nextTheme
  }, [paletteType])

  useEffect(() => {
    // check cookie for palette
    const regex = /(?:(?:^|.*;*)paletteType*=*([^;]*).*$)|^.*$/
    const nextPaletteType = document.cookie.replace(regex, '$1')
    if (['light', 'dark'].includes(nextPaletteType)) {
      setPaletteType(nextPaletteType)
    }
  }, [])

  useEffect(() => {
    if (firebase) {
      // firebase.analytics.setUserProperties(firebase.analytics, { palette: paletteType })
    }
  }, [firebase, paletteType])

  const [openContactDialog, setOpenContactDialog] = useState(false)

  const togglePaletteType = () => {
    const nextPaletteType = theme.palette.type === 'light' ? 'dark' : 'light'
    // persist palette
    document.cookie = `paletteType=${nextPaletteType};path=/;max-age=31536000`
    setPaletteType(nextPaletteType)
  }

  const onClickOpenContactDialog = () => {
    try {
      firebase.analytics.logEvent(firebase.analytics, 'select_content', { id: 'contact', name: 'Contact' })
    } catch (err) {
      console.log('Error logging contact event', err)
    }
    setOpenContactDialog(true)
  }

  const onCloseContactDialog = () => {
    setOpenContactDialog(false)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <HideOnScroll>
          <AppBar position="sticky">
            <Toolbar>
              <Link component={RouterLink} color="inherit" underline="none" to="/">
                <Typography variant="h6" color="inherit" noWrap>
                  Bakeneko
                </Typography>
              </Link>
              <div className={classes.grow} />
              <Tooltip title='Profile' enterDelay={300}>
                <IconButton
                  color="inherit"
                  component={RouterLink} to="/profile"
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Contact' enterDelay={300}>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={onClickOpenContactDialog}
                >
                  <EmailIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Toggle light/dark theme' enterDelay={300}>
                <IconButton
                  color="inherit"
                  onClick={togglePaletteType}
                >
                  {theme.palette.type === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <ContactDialog open={openContactDialog} onClose={onCloseContactDialog} />
        <main>
          <Switch>
            <Route exact path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.PROFILE} component={ProfilePage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            <Route component={HomePage} />
          </Switch>
        </main>
      </Router>
    </ThemeProvider>
  )
}

export default App
