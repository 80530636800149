import { CardContent, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center'
  },
  title: {
    alignSelf: 'start'
  }
}))

const Location = props => {
  const classes = useStyles()

  if (props.time == null || props.label == null) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Location
        </Typography>
        <Typography variant="h6">
          {props.label}
        </Typography>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
Location.propTypes = {
  time: PropTypes.instanceOf(Date),
  label: PropTypes.string
}

export default Location
