import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

function NightThunderstorm (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <path d="M4.08 16.88c0 1.11.33 2.1.99 2.98s1.52 1.47 2.58 1.79l-.66 1.69c-.03.14.02.21.15.21h2.12l-.97 3.51h.29l3.91-4.94c.04-.05.04-.1.01-.15-.03-.05-.08-.07-.15-.07h-2.18l2.48-4.63c.07-.14.03-.22-.13-.22H9.56c-.09 0-.16.05-.23.14l-1.07 2.88c-.72-.18-1.31-.57-1.78-1.17s-.7-1.27-.7-2.01c0-.83.28-1.55.85-2.17.57-.62 1.27-.97 2.1-1.07l.52-.08c.13 0 .2-.06.2-.17l.07-.52c.11-1.08.56-1.99 1.37-2.72s1.76-1.1 2.85-1.1c1.08 0 2.03.37 2.85 1.1s1.29 1.64 1.41 2.71l.07.59c0 .11.06.17.18.17h1.62c.91 0 1.68.32 2.33.95s.97 1.4.97 2.29c0 .85-.3 1.59-.9 2.21-.6.62-1.33.97-2.2 1.04-.12 0-.19.06-.19.17v1.38c0 .12.06.18.19.18.88-.03 1.68-.27 2.41-.72a4.94 4.94 0 002.36-4.25c0-.87-.22-1.68-.66-2.45.79-.76 1.31-1.63 1.56-2.62l.14-.72c.01-.01.02-.04.02-.07 0-.07-.05-.12-.16-.15l-.56-.18c-.57-.16-1.06-.44-1.46-.82a3.4 3.4 0 01-.87-1.23c-.17-.44-.26-.88-.26-1.32 0-.26.03-.53.08-.8l.14-.61c.04-.1 0-.18-.14-.23-.21-.09-.51-.17-.9-.26s-.77-.13-1.15-.13c-.36 0-.73.04-1.12.13-.38.09-.78.22-1.19.41-.41.18-.81.45-1.2.8-.39.35-.72.75-1 1.22-.82-.3-1.62-.45-2.38-.45-1.41 0-2.67.44-3.76 1.31s-1.8 1.99-2.11 3.36c-1.11.26-2.02.84-2.74 1.74a4.91 4.91 0 00-1.04 3.07zm8.1 9.82c0 .16.05.32.15.46.1.15.25.25.45.3.11.02.21.03.3.03.41 0 .66-.21.76-.63l2.32-8.79c.06-.24.04-.45-.07-.65a.786.786 0 00-.5-.39.814.814 0 00-.65.06c-.2.11-.34.27-.4.49l-2.32 8.84c-.03.1-.04.19-.04.28zm4.17-3.02c0 .16.05.32.15.46.1.14.25.25.46.31.03 0 .08 0 .15.01.07.01.13.01.16.01.38 0 .62-.21.72-.63l1.5-5.77c.06-.24.04-.46-.08-.66a.799.799 0 00-.51-.38.814.814 0 00-.65.06c-.2.11-.33.27-.39.5l-1.5 5.82c0 .1-.01.19-.01.27zm1.24-14.93c.33-.57.77-1 1.33-1.3.55-.3 1.14-.45 1.76-.45.12 0 .22 0 .27.01v.32c0 .96.26 1.87.78 2.73s1.25 1.51 2.17 1.97c-.18.42-.44.77-.79 1.07-.92-.79-1.99-1.18-3.22-1.18h-.32c-.28-1.26-.94-2.31-1.98-3.17z"/>
    </SvgIcon>
  )
}

export default NightThunderstorm
