import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center'
  },
  title: {
    alignSelf: 'start'
  },
  icon: {
    height: 40
  }
}))

const MediaLibraryMonthlyStats = props => {
  const classes = useStyles()

  if (props.movieCount == null || props.showCount == null || props.episodeCount == null || props.runtime == null) {
    return (
      <LoadingCard />
    )
  }

  const hours = Math.floor(props.runtime / 3600)
  const minutes = Math.floor(props.runtime % 3600 / 60)
  const runtimeLabel = `${hours} h ${minutes} min`
  const format = (count, label) => `${count} ${label}${count > 1 ? 's' : ''}`

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Watched
        </Typography>
        <Tooltip title={`Watched: ${runtimeLabel}`} enterDelay={300} arrow>
          <img className={classes.icon} src="/images/jellyfin_logo.svg" alt="Jellyfin" />
        </Tooltip>
        <Typography variant="caption">
          {format(props.movieCount, 'movie')}
        </Typography>
        <Typography variant="caption">
          {format(props.showCount, 'show') + ' ' + format(props.episodeCount, 'episode')}
        </Typography>
        <Typography variant="caption">
          {runtimeLabel}
        </Typography>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          this month
        </Typography>
      </CardContent>
    </Card>
  )
}
MediaLibraryMonthlyStats.propTypes = {
  movieCount: PropTypes.number,
  showCount: PropTypes.number,
  episodeCount: PropTypes.number,
  runtime: PropTypes.number
}

export default MediaLibraryMonthlyStats
