import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

function MugHot (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path d="M400 192H32c-17.7 0-32 14.3-32 32v192c0 53 43 96 96 96h192c53 0 96-43 96-96h16c61.8 0 112-50.2 112-112s-50.2-112-112-112zm-64 224c0 26.5-21.5 48-48 48H96c-26.5 0-48-21.5-48-48V240h288v176zm64-48h-16V240h16c35.3 0 64 28.7 64 64s-28.7 64-64 64zM239.1 146.5c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C223.8 5.9 217 0 208.8 0h-16.4c-9.8 0-17.5 8.5-16.3 18 3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zm-112 0c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C111.8 5.9 105 0 96.8 0H80.4C70.6 0 63 8.5 64.1 18c3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1z"/>
    </SvgIcon>
  )
}

export default MugHot
