import { CardContent, LinearProgress as MuiLinearProgress, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import SnoozeIcon from '../Icons/Snooze'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    alignSelf: 'start'
  },
  progress: {
    height: 6
  },
  icon: {
    fontSize: theme.typography.pxToRem(36),
    transform: 'translate(0, 0)',
    opacity: 1,
    animation: '$sleep 3s infinite ease-out'
  },
  '@keyframes sleep': {
    '0%': {
      transform: 'translate(-16%, 16%)',
      opacity: 0
    },
    '50%': {
      opacity: 1
    },
    '100%': {
      transform: 'translate(16%, -16%)',
      opacity: 0
    }
  }
}))

const LinearProgress = withStyles((theme) => ({
  root: {
    width: '100%'
  },
  colorPrimary: {
    backgroundColor: theme.palette.secondary.main
  }
}))(MuiLinearProgress)

const Sleep = props => {
  const classes = useStyles()

  if (Object.values(props).some(value => value == null)) {
    return (
      <LoadingCard />
    )
  }

  const sleepDuration = props.remSleepDuration + props.lightSleepDuration + props.deepSleepDuration
  const deepSleepPercent = Math.round(props.deepSleepDuration / sleepDuration * 100)

  const hours = Math.floor(sleepDuration / 3600)
  const minutes = Math.floor((sleepDuration % 3600) / 60)
  const durationLabel = `${hours} h ${minutes} min`

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Sleep
        </Typography>
        <Tooltip title={`Sleep: ${durationLabel}`} enterDelay={300} arrow placement="top">
          <div>
            <SnoozeIcon className={classes.icon} />
          </div>
        </Tooltip>
        <Typography variant="h6">
          {durationLabel}
        </Typography>
        <Tooltip title={`Deep sleep: ${deepSleepPercent}%`} enterDelay={300} arrow>
          <LinearProgress className={classes.progress} variant="determinate" value={deepSleepPercent} />
        </Tooltip>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.endTime)}
        </Typography>
      </CardContent>
    </Card>
  )
}
Sleep.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  duration: PropTypes.number,
  awakeDuration: PropTypes.number,
  remSleepDuration: PropTypes.number,
  lightSleepDuration: PropTypes.number,
  deepSleepDuration: PropTypes.number
}

export default Sleep
