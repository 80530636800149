import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Activity from '../Activity'
import Books from '../Books'
import Calories from '../Calories'
import { useFirebase } from '../Firebase'
import HeartRate from '../HeartRate'
import Location from '../Location'
import Mails from '../Mails'
import { MediaLibraryHistory, MediaLibraryInfo, MediaLibraryMonthlyStats } from '../MediaLibrary'
import ProfileCard from '../Profile/ProfileCard'
import RadarChartCard from '../RadarChartCard'
import Sleep from '../Sleep'
import SocialMedia from '../SocialMedia'
import Steps from '../Steps'
import Weather from '../Weather'
import Weight from '../Weight'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))

const Home = props => {
  const theme = useTheme()
  const classes = useStyles()
  const firebase = useFirebase()
  const [profile, setProfile] = useState()
  const [snapshot, setSnapshot] = useState()

  useEffect(() => {
    document.title = 'Stephane "Bakeneko" Dupont'
  }, [])

  useEffect(() => {
    if (!firebase) {
      return
    }
    const { doc, getDoc } = firebase.db.imports
    getDoc(doc(firebase.profile(), 'me'))
      .then(snapshot => {
        if (snapshot.exists) {
          const doc = snapshot.data()
          setProfile({
            ...doc,
            attributes: (doc.attributes || []).sort((a, b) => a.order - b.order),
            baseSkills: (doc.baseSkills || []).sort((a, b) => a.order - b.order),
            birthTime: doc.birthTime.toDate()
          })
        }
      })
  }, [firebase])

  useEffect(() => {
    if (!firebase) {
      return
    }
    const { getDocs, limit, orderBy, query } = firebase.db.imports
    getDocs(query(
      firebase.snapshots(),
      orderBy('time', 'desc'),
      limit(1)
    )).then(snapshot => {
      if (snapshot.size) {
        const doc = snapshot.docs[0].data()
        setSnapshot({
          ...doc,
          time: doc.time.toDate()
        })
      }
    })
  }, [firebase])

  return (
    <React.Fragment>
      <Container className={classes.content} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item key='profile' xs={12} md={6}>
            <ProfileCard profile={profile} more />
          </Grid>
          <Grid item key='attributes' xs={6} sm={4} md={3} lg={2}>
            <Link component={RouterLink} color="inherit" underline="none" to="/profile">
              <RadarChartCard title="Attributes" data={profile && profile.attributes} color={theme.palette.primary.light} />
            </Link>
          </Grid>
          <Grid item key='skills' xs={6} sm={4} md={3} lg={2}>
            <Link component={RouterLink} color="inherit" underline="none" to="/profile">
              <RadarChartCard title="Skills" data={profile && profile.baseSkills} color={theme.palette.secondary.light} />
            </Link>
          </Grid>
          <Grid item key='socialMedia' xs={6} sm={4} md={3} lg={2}>
            <SocialMedia />
          </Grid>
          <Grid item key='location' xs={6} sm={4} md={3} lg={2}>
            <Location label={snapshot?.location?.label} time={snapshot?.location?.time?.toDate()} />
          </Grid>
          <Grid item key='weather' xs={6} sm={4} md={3} lg={2}>
            <Weather { ...{ ...snapshot?.weather, time: snapshot?.weather?.time?.toDate() } } />
          </Grid>
          <Grid item key='heartRate' xs={6} sm={4} md={3} lg={2}>
            <HeartRate value={snapshot?.heartRate?.value} time={snapshot?.heartRate?.time?.toDate()} />
          </Grid>
          <Grid item key='weight' xs={6} sm={4} md={3} lg={2}>
            <Weight value={snapshot?.weight?.value} time={snapshot?.weight?.time?.toDate()} />
          </Grid>
          <Grid item key='activity' xs={6} sm={4} md={3} lg={2}>
            <Activity
              time={snapshot?.activity?.endTime?.toDate()}
              label={snapshot?.activity?.label}
              type={snapshot?.activity?.type}
            />
          </Grid>
          <Grid item key='sleep' xs={6} sm={4} md={3} lg={2}>
            <Sleep
              startTime={snapshot?.sleep?.startTime?.toDate()}
              endTime={snapshot?.sleep?.endTime?.toDate()}
              duration={(snapshot?.sleep?.durationMillis ?? 0) / 1000}
              remSleepDuration={(snapshot?.sleep?.remSleepDurationMillis ?? 0) / 1000}
              lightSleepDuration={(snapshot?.sleep?.lightSleepDurationMillis ?? 0) / 1000}
              deepSleepDuration={(snapshot?.sleep?.deepSleepDurationMillis ?? 0) / 1000}
              />
          </Grid>
          {/* <Grid item key='mood' xs={6} sm={4} md={3} lg={2}>
            <Mood />
          </Grid> */}
          <Grid item key='mails' xs={6} sm={4} md={3} lg={2}>
            <Mails { ...{ ...snapshot?.mails, time: snapshot?.mails?.time?.toDate() } } />
          </Grid>
          <Grid item key='steps' xs={6} sm={4} md={3} lg={2}>
            <Steps value={snapshot?.steps?.value} />
          </Grid>
          <Grid item key='calories' xs={6} sm={4} md={3} lg={2}>
            <Calories value={snapshot?.calories?.value} />
          </Grid>
          <Grid item key='mediaLibraryInfo' xs={6} sm={4} md={3} lg={2}>
            <MediaLibraryInfo
              time={snapshot?.mediaLibraryStat?.time?.toDate()}
              movieCount={snapshot?.mediaLibraryStat?.movieCount}
              showCount={snapshot?.mediaLibraryStat?.showCount}
              episodeCount={snapshot?.mediaLibraryStat?.episodeCount}
              runtime={(snapshot?.mediaLibraryStat?.movieCumulativeRuntime ?? 0) + (snapshot?.mediaLibraryStat?.showCumulativeRuntime ?? 0)}
              />
          </Grid>
          <Grid item key='mediaLibraryMonthlyStats' xs={6} sm={4} md={3} lg={2}>
            <MediaLibraryMonthlyStats
              movieCount={snapshot?.watchMonthlyStat?.movieCount}
              showCount={snapshot?.watchMonthlyStat?.showCount}
              episodeCount={snapshot?.watchMonthlyStat?.episodeCount}
              runtime={(snapshot?.watchMonthlyStat?.movieCumulativeRuntime ?? 0) + (snapshot?.watchMonthlyStat?.showCumulativeRuntime ?? 0)}
              />
          </Grid>
          <Grid item key='mediaLibraryHistory' xs={12} sm={4} md={3} lg={2}>
            <MediaLibraryHistory { ...{ ...snapshot?.watchedMedia, time: snapshot?.watchedMedia?.time?.toDate() } } />
          </Grid>
          <Grid item key='books' xs={6} sm={4} md={3} lg={2}>
            <Books
              { ...{ ...snapshot?.readBook, time: snapshot?.readBook?.time?.toDate() } }
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Home
