import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

function Cloud (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <path d="M4.61 16.88c0-1.15.36-2.17 1.08-3.07.72-.9 1.63-1.48 2.74-1.73.31-1.37 1.02-2.49 2.11-3.37s2.35-1.32 3.76-1.32c1.38 0 2.61.43 3.69 1.28s1.78 1.95 2.1 3.29h.33c.9 0 1.73.22 2.49.65s1.37 1.03 1.81 1.79c.44.76.67 1.58.67 2.48a4.94 4.94 0 01-2.36 4.25c-.73.45-1.54.69-2.41.72H9.41c-1.34-.06-2.47-.57-3.4-1.53-.93-.95-1.4-2.1-1.4-3.44zm1.71 0c0 .87.3 1.62.9 2.26s1.33.98 2.19 1.03H20.6c.86-.04 1.59-.39 2.19-1.03.61-.64.91-1.4.91-2.26 0-.88-.33-1.63-.98-2.27-.65-.64-1.42-.96-2.32-.96h-1.6c-.11 0-.17-.06-.17-.18l-.07-.57c-.11-1.08-.58-1.99-1.4-2.72-.82-.73-1.77-1.1-2.86-1.1-1.09 0-2.05.37-2.85 1.1-.81.73-1.27 1.64-1.37 2.72l-.08.57c0 .12-.07.18-.2.18h-.53c-.84.1-1.54.46-2.1 1.07s-.85 1.33-.85 2.16z"/>
    </SvgIcon>
  )
}

export default Cloud
