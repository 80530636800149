import { CardContent, Typography, useTheme } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import RadarChart from 'react-svg-radar-chart'

import LoadingCard from '../LoadingCard'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(-1)
  }
}))

const RadarChartCard = props => {
  const { title, data, color } = props

  const theme = useTheme()

  const classes = useStyles()

  if (!data) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        { title && (
          <Typography variant="subtitle2" color="textSecondary">
            {title}
          </Typography>
        )}
        <div className={classes.chart}>
          <RadarChart style={{ }}
            captions={Object.fromEntries(data.map(attr => [attr.key, attr.label]))}
            data={[
              {
                data: Object.fromEntries(data.map(attr => [attr.key, parseFloat(attr.value)])),
                meta: { color: color || theme.palette.primary.light }
              }
            ]}
            options={{
              axes: false,
              scales: 5,
              captions: true,
              captionMargin: 24,
              dots: false,
              zoomDistance: 1.4,
              scaleProps: () => ({
                fill: 'none',
                stroke: theme.palette.grey[500],
                strokeWidth: '.6',
                shapeRendering: 'geometricPrecision'
              }),
              shapeProps: () => ({
                className: 'shape',
                fillOpacity: 0.5
              }),
              captionProps: () => ({
                className: 'caption',
                textAnchor: 'middle',
                fontSize: 14,
                fill: theme.palette.text.primary,
                fontFamily: theme.typography.subtitle1.fontFamily
              })
            }}
            size={170}
          />
        </div>
      </CardContent>
    </Card>
  )
}

RadarChartCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string
}

export default RadarChartCard
