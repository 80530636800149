import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

function NightRain (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 30 30">
      <path d="M4.07 16.9c0 1.33.47 2.48 1.4 3.44s2.07 1.47 3.4 1.53c.12 0 .18-.06.18-.17v-1.34c0-.11-.06-.17-.18-.17a3.15 3.15 0 01-2.19-1.03c-.6-.64-.9-1.39-.9-2.26 0-.83.28-1.55.85-2.17.57-.62 1.27-.97 2.1-1.07l.53-.04c.13 0 .2-.06.2-.17l.07-.54c.11-1.08.57-1.99 1.38-2.72a4.15 4.15 0 012.86-1.1c1.09 0 2.04.37 2.86 1.1.82.73 1.28 1.64 1.4 2.72l.08.57c0 .12.06.18.17.18h1.62c.89 0 1.67.32 2.32.96.65.64.98 1.4.98 2.28 0 .87-.3 1.62-.9 2.26-.6.64-1.33.98-2.19 1.03-.14 0-.21.06-.21.17v1.34c0 .11.07.17.21.17 1.33-.04 2.46-.55 3.38-1.51.93-.97 1.39-2.12 1.39-3.45 0-.88-.23-1.7-.68-2.46.81-.73 1.33-1.6 1.58-2.62l.14-.72c.01-.01.02-.03.02-.07 0-.07-.05-.13-.16-.16l-.56-.18c-.57-.16-1.06-.44-1.46-.83-.41-.39-.7-.8-.87-1.23-.17-.43-.26-.86-.26-1.28-.02-.22.01-.5.08-.82l.14-.61c.04-.1 0-.18-.14-.24l-.79-.24c-.45-.1-.87-.15-1.27-.15-.38 0-.76.04-1.14.13-.39.09-.79.22-1.2.41-.41.18-.81.45-1.2.8-.39.35-.72.75-1 1.22-.82-.3-1.6-.45-2.33-.45-1.41 0-2.67.44-3.76 1.32s-1.8 2-2.11 3.37c-1.11.26-2.02.83-2.74 1.73-.74.89-1.1 1.92-1.1 3.07zm5.56 6.84c0 .17.05.33.16.49.11.16.27.27.49.33.23.07.45.05.64-.04.2-.1.33-.28.4-.56l1.43-5.87c.06-.25.03-.48-.08-.67a.765.765 0 00-.52-.37.737.737 0 00-.63.07c-.2.11-.34.28-.41.51l-1.44 5.9c0 .01-.01.04-.02.09-.01.05-.02.09-.02.12zm2.61 3.07c0 .16.05.31.15.46.1.15.25.25.45.31.11.03.19.04.24.04.44 0 .71-.2.82-.59l2.25-8.93c.06-.24.04-.46-.07-.65a.856.856 0 00-.5-.39c-.23-.07-.45-.05-.66.07s-.34.28-.39.5l-2.26 8.92c0 .01 0 .05-.01.12-.02.06-.02.11-.02.14zm4.16-2.99c0 .36.21.6.63.74.14.04.24.06.3.06.11 0 .23-.02.35-.07.21-.08.34-.28.39-.58l1.43-5.87c.06-.24.04-.45-.08-.65a.848.848 0 00-.51-.39.804.804 0 00-.66.07c-.21.11-.33.28-.38.51l-1.43 5.9c-.02.16-.04.26-.04.28zm1.18-15.05c.32-.58.75-1.02 1.31-1.33.55-.3 1.14-.45 1.76-.44.12 0 .21 0 .27.01v.3c-.01.97.24 1.88.77 2.75.52.86 1.26 1.52 2.21 1.97-.22.46-.49.81-.79 1.07-.92-.76-1.99-1.13-3.23-1.13h-.31c-.27-1.27-.93-2.33-1.99-3.2z"/>
    </SvgIcon>
  )
}

export default NightRain
