import { Avatar, Button, CardActions, CardContent, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import HomeIcon from '@material-ui/icons/Home'
import UpdateIcon from '@material-ui/icons/Update'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import WorkIcon from '@material-ui/icons/Work'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import LoadingCard from '../LoadingCard'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  details: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column'
  },
  avatar: {
    margin: '16px 0px 16px 16px',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(15),
      height: theme.spacing(15)
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(20),
      height: theme.spacing(20)
    }
  },
  line: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    margin: theme.spacing(0, 1)
  },
  action: {
    marginLeft: 'auto'
  }
}))

const ProfileCard = props => {
  const { profile, more } = props
  const [runtime, setRuntime] = useState('00:000:00:00:00')

  const classes = useStyles()

  useEffect(() => {
    if (!profile || !profile.birthTime) {
      return
    }
    const processRunTime = () => {
      let seconds = Math.floor((Date.now() - profile.birthTime.getTime()) / 1000)
      const daySeconds = 24 * 3600
      const yearSeconds = 365.2425 * daySeconds
      let years = Math.floor(seconds / yearSeconds)
      seconds -= years * yearSeconds
      let days = Math.floor(seconds / daySeconds)
      seconds -= days * daySeconds
      let hours = Math.floor(seconds / 3600)
      seconds -= hours * 3600
      let minutes = Math.floor(seconds / 60)
      seconds -= minutes * 60
      if (years < 10) {
        years = `0${years}`
      }
      if (days < 10) {
        days = `0${days}`
      }
      if (hours < 10) {
        hours = `0${hours}`
      }
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      setRuntime(`${years}:${days}:${hours}:${minutes}:${seconds}`)
    }
    processRunTime()
    const interval = setInterval(() => {
      processRunTime()
    }, 1000)
    return () => clearInterval(interval)
  }, [profile])

  if (!profile) {
    return (
      <LoadingCard />
    )
  }

  const availability = profile.available
    ? (
    <React.Fragment>
      <EmojiPeopleIcon className={classes.icon} />
      <Typography variant="subtitle1">
        Available for hire
      </Typography>
    </React.Fragment>
      )
    : (
    <React.Fragment>
      <WorkIcon className={classes.icon} />
      <Typography variant="subtitle1">
        Busy working
      </Typography>
    </React.Fragment>
      )

  return (
    <Card className={classes.card}>
      <Avatar className={classes.avatar} variant="rounded" src="/images/picture.jpg" title={profile.name} />
      <div className={classes.details}>
        <CardContent>
          <Typography component="h6" variant="h6" gutterBottom>
            {profile.name}
          </Typography>
          <div className={classes.line}>
            <VerifiedUserIcon className={classes.icon} />
            <Typography variant="subtitle1">
              {profile.title}
            </Typography>
          </div>
          <div className={classes.line}>
            <UpdateIcon className={classes.icon} />
            <Typography variant="subtitle1">
              {runtime}
            </Typography>
          </div>
          <div className={classes.line}>
            <HomeIcon className={classes.icon} />
            <Typography variant="subtitle1">
              {profile.residence}
            </Typography>
          </div>
          <div className={classes.line}>
            {availability}
          </div>
        </CardContent>
        {more && (
          <CardActions className={classes.action}>
            <Button component={RouterLink} to="/profile" size="small" color="primary">View Profile</Button>
          </CardActions>
        )}
      </div>
    </Card>
  )
}

ProfileCard.propTypes = {
  profile: PropTypes.object,
  more: PropTypes.bool
}

export default ProfileCard
