import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics'
import { getApp, initializeApp } from 'firebase/app'
import { collection, doc, enableMultiTabIndexedDbPersistence, getDoc, getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  projectId: process.env.REACT_APP_PROJECT_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

class Firebase {
  constructor () {
    let app
    try {
      app = getApp()
    } catch {}
    if (!app) {
      app = initializeApp(config)
      enableMultiTabIndexedDbPersistence(getFirestore()).catch(err => {
        console.log('Error enabling firestore persistence', err)
      })
    }
    this.analytics = getAnalytics()
    this.analytics.logEvent = logEvent
    this.analytics.setUserProperties = setUserProperties
    this.storage = getStorage(app)
    this.db = getFirestore()
    this.db.imports = { doc, getDoc, getDocs, limit, orderBy, query, where }
  }

  storageRef (url) { return ref(this.storage, url) }
  storageDownloadURL (ref) { return getDownloadURL(ref) }

  snapshots () { return collection(this.db, 'snapshots') }
  profile () { return collection(this.db, 'profile') }
}

export default Firebase
