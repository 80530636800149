import { CardContent, CircularProgress } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const LoadingCard = props => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent>
        <CircularProgress size={65} />
      </CardContent>
    </Card>
  )
}

export default LoadingCard
