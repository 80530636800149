const SEC_ARRAY = [
  60, // 60 seconds in 1 min
  60, // 60 mins in 1 hour
  24, // 24 hours in 1 day
  7, // 7 days in 1 week
  365 / 7 / 12, // 4.345238095238096 weeks in 1 month
  12 // 12 months in 1 year
]

const UNITS = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year']

const differenceInSeconds = (date, otherDate) => {
  return ((otherDate || new Date()).getTime() - date.getTime()) / 1000
}

const formatAgo = (date, relativeDate) => {
  let diff = Math.abs(differenceInSeconds(date, relativeDate))
  let idx = 0
  for (; diff >= SEC_ARRAY[idx] && idx < SEC_ARRAY.length; idx++) {
    diff /= SEC_ARRAY[idx]
  }
  diff = Math.floor(diff)
  idx *= 2
  if (diff > (idx === 0 ? 9 : 1)) idx += 1

  if (idx === 0) return 'just now'
  let unit = UNITS[Math.floor(idx / 2)]
  if (diff > 1) unit += 's'
  return `${diff} ${unit} ago`
}

export default formatAgo
