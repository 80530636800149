import { CardContent, Chip, Link, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import HomeIcon from '@material-ui/icons/Home'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import SchoolIcon from '@material-ui/icons/School'
import WorkIcon from '@material-ui/icons/Work'
import Timeline from '@material-ui/lab/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import MuiTimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import MuiTimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import React from 'react'

const TimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none'
    }
  }
})(MuiTimelineItem)

const TimelineContent = withStyles({
  root: {
    flex: 1,
    padding: '18px 16px 6px 16px'
  }
})(MuiTimelineContent)

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  timeline: {
    padding: 0
  },
  paper: {
    padding: '6px 16px',
    marginTop: theme.spacing(1.5)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  youtubeContainer: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  youtubePlayer: {
    overflow: 'hidden',
    width: '50%',
    paddingBottom: '28.12%', // 16:9
    height: 0,
    position: 'relative',
    margin: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: '56.25%' // 16:9
    },
    '& > iframe': {
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      position: 'absolute'
    }
  }
}))

const Experiences = props => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          Experiences
        </Typography>
        <Timeline className={classes.timeline}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <HomeIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                2021-Now • Freelance Developer
              </Typography>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="subtitle1">
                  Multiple projects including Android, Unity, frontend and backend development
                </Typography>
                <div className={classes.chips}>
                  {['Kotlin', 'Java', 'TypeScript', 'React', 'Node.js', 'C#', 'Unity', 'MongoDB', 'PostgreSQL', 'Docker', 'Kubernetes'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <WorkIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                2022 • Mobile and web development engineer at <Link href="https://www.apparence.io" target="_blank" rel="noopener" color="textPrimary" underline="hover">Apparence.io</Link>, Grenoble
              </Typography>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="h6">
                  Mon Suivi Diet
                </Typography>
                <Typography variant="subtitle1">
                  Mobile and web app for dietitians and their patients
                </Typography>
                <div className={classes.chips}>
                  {['Flutter', 'Dart', 'TypeScript', 'Angular', 'Node.js', 'Android', 'iOS', 'Firebase'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <PersonAddIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                2018-2021 • Senior Developer at <Link href="https://explorgames.com" target="_blank" rel="noopener" color="textPrimary" underline="hover">Atelier Nature</Link>, Mâcon
              </Typography>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="h6">
                  Explor Games
                </Typography>
                <Typography variant="subtitle1">
                  Mobile game and microservices-based architecture
                </Typography>
                <div className={classes.chips}>
                  {['Kotlin', 'Java', 'React Native', 'Firebase', 'AR', 'Android', 'Node.js', 'MongoDB', 'Docker', 'Kubernetes'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
                <div className={classes.youtubeContainer}>
                  <div className={classes.youtubePlayer}>
                    <iframe id="ytplayer" title="Explor Games" type="text/html"
                      src="https://www.youtube.com/embed/z3uH4L1ZPXo?enablejsapi=1&color=white&iv_load_policy=3&rel=0&modestbranding=1" frameBorder="0" allowFullScreen>
                    </iframe>
                  </div>
                  <div className={classes.youtubePlayer}>
                    <iframe id="ytplayer" title="Explor Games" type="text/html"
                      src="https://www.youtube.com/embed/6bnPcJPDNB4?enablejsapi=1&color=white&iv_load_policy=3&rel=0&modestbranding=1" frameBorder="0" allowFullScreen>
                    </iframe>
                  </div>
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <PersonAddIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                2017 • Team expansion
              </Typography>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="h6">
                  JADE
                </Typography>
                <Typography variant="subtitle1">
                  Mobile application and php backend
                </Typography>
                <div className={classes.chips}>
                  {['Java', 'Swift', 'Objective-C', 'PHP', 'MySQL', 'Android', 'iOS'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <WorkIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                2015 • Joined <Link href="https://explorgames.com" target="_blank" rel="noopener" color="textPrimary" underline="hover">Atelier Nature</Link>, Mâcon, as Developer
              </Typography>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="h6">
                  SmartForest
                </Typography>
                <Typography variant="subtitle1">
                  Mobile application
                </Typography>
                <div className={classes.chips}>
                  {['Java', 'Objective-C', 'Android', 'iOS'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="h6">
                  CADOE
                </Typography>
                <Typography variant="subtitle1">
                  Home automation
                </Typography>
                <div className={classes.chips}>
                  {['Python', 'CANopen', 'HTML5', 'PHP', 'MySQL'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="h6">
                  RegulPlus
                </Typography>
                <Typography variant="subtitle1">
                  Ambulance management system
                </Typography>
                <div className={classes.chips}>
                  {['.NET', 'PostgreSQL', 'Java', 'Objective-C', 'Android', 'iOS'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="h6">
                  GPtO
                </Typography>
                <Typography variant="subtitle1">
                  PDA/Mobile visioguide application
                </Typography>
                <div className={classes.chips}>
                  {['.NET', 'Java', 'Objective-C', 'Android', 'iOS'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <WorkIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                2011 • Joined ISP Informatique, Hauteville-Lompnes, as Junior Developer
              </Typography>
              <Paper className={classes.paper} variant="outlined" square>
                <Typography variant="subtitle1">
                  Development of internal tools for automated testing and continuous integration
                </Typography>
                <div className={classes.chips}>
                  {['C#', 'ASP.NET'].map((chip, index) => {
                    return <Chip key={index} label={chip} size="small" color="default" />
                  })}
                </div>
              </Paper>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <WorkIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                July 2009 • Joined Ineas, Annecy, as Junior Developer
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                June 2009 • Graduated DUT in Computer Science from the Annecy Technology Institute
              </Typography>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <WorkIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                April 2009 • Internship at Ineas, Annecy
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </CardContent>
    </Card>
  )
}

export default Experiences
