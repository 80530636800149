import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import ChipCard from '../ChipCard'
import { useFirebase } from '../Firebase'
import LoadingCard from '../LoadingCard'
import RadarChartCard from '../RadarChartCard'
import SocialMedia from '../SocialMedia'
import Experiences from './Experiences'
import ProfileCard from './ProfileCard'

const useStyles = makeStyles((theme) => ({
  content: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))

const Profile = props => {
  const theme = useTheme()
  const classes = useStyles()
  const firebase = useFirebase()
  const [profile, setProfile] = useState()

  useEffect(() => {
    document.title = 'Profile'
  }, [])

  useEffect(() => {
    if (!firebase) {
      return
    }
    const { doc, getDoc } = firebase.db.imports
    getDoc(doc(firebase.profile(), 'me'))
      .then(snapshot => {
        if (snapshot.exists) {
          const doc = snapshot.data()
          setProfile({
            ...doc,
            birthTime: doc.birthTime.toDate(),
            developementSkills: (doc.developementSkills || []).sort((a, b) => a.order - b.order),
            administrationSkills: (doc.administrationSkills || []).sort((a, b) => a.order - b.order),
            expertSkills: (doc.expertSkills || []).sort((a, b) => a.order - b.order),
            bonusSkills: (doc.bonusSkills || []).sort((a, b) => a.order - b.order),
            languages: (doc.languages || []).sort((a, b) => a.order - b.order),
            interests: (doc.interests || []).sort((a, b) => a.order - b.order)
          })
        }
      })
  }, [firebase])

  if (!profile) {
    return (
      <LoadingCard />
    )
  }

  return (
    <Container className={classes.content} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item key='header' xs={12} md={6}>
          <ProfileCard profile={profile} />
        </Grid>
        <Grid item key='developementSkills' xs={6} sm={4} md={3} lg={2}>
          <RadarChartCard title="Development" data={profile && profile.developementSkills} color={theme.palette.primary.light} />
        </Grid>
        <Grid item key='administrationSkills' xs={6} sm={4} md={3} lg={2}>
          <RadarChartCard title="Administration" data={profile && profile.administrationSkills} color={theme.palette.secondary.light} />
        </Grid>
        <Grid item key='socialMedia' xs={6} sm={4} md={3} lg={2}>
          <SocialMedia />
        </Grid>
        <Grid item key='expertiseSkills' xs={12} md={6}>
          <ChipCard title="Expertise" chips={profile && profile.expertSkills} color="primary" size="small" />
        </Grid>
        <Grid item key='bonusSkills' xs={12} md={6}>
          <ChipCard title="Bonus" chips={profile && profile.bonusSkills} color="secondary" size="small" />
        </Grid>
        <Grid item key='languages' xs={12} md={6}>
          <ChipCard title="Languages" chips={profile && profile.languages.map(l => ({ ...l, color: l.value >= 0.7 ? 'primary' : 'default' }))} color="default" />
        </Grid>
        <Grid item key='interests' xs={12} md={6}>
          <ChipCard title="Interests" chips={profile && profile.interests} color="secondary" size="small" />
        </Grid>
        <Grid item key='experiences' xs={12}>
          <Experiences />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Profile
