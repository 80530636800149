import { CardContent, Chip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}))

const ChipCard = props => {
  const { title, chips, color, size } = props
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        { title && (
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {title}
          </Typography>
        )}
        <div className={classes.chips}>
          {chips && chips.map(chip => {
            return <Chip key={chip.key} label={chip.label} size={size || 'medium'} color={chip.color || color || 'default'} />
          })}
        </div>
      </CardContent>
    </Card>
  )
}

ChipCard.propTypes = {
  title: PropTypes.string,
  chips: PropTypes.array,
  color: PropTypes.string,
  size: PropTypes.string
}

export default ChipCard
