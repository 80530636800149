import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    position: 'relative',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center'
  },
  title: {
    alignSelf: 'start'
  },
  icon: {
    height: 40
  }
}))

const MediaLibraryInfo = props => {
  const classes = useStyles()

  if (props.time == null || props.movieCount == null || props.showCount == null || props.episodeCount == null || props.runtime == null) {
    return (
      <LoadingCard />
    )
  }

  const formatDuration = (duration) => {
    const days = Math.floor(duration / 86400)
    const hours = Math.floor(duration % 86400 / 3600)
    const minutes = Math.floor(duration % 3600 / 60)
    return days > 0 ? `${days} d ${hours} h` : `${hours} h ${minutes} min`
  }
  const formatQuantity = (count, label) => `${count} ${label}${count > 1 ? 's' : ''}`

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Media Library
        </Typography>
        <Tooltip title={'Media library: ' + formatQuantity(props.movieCount, 'movie') + ' ' + formatQuantity(props.showCount, 'show') + ' ' + formatQuantity(props.episodeCount, 'episode')} enterDelay={300} arrow>
          <img className={classes.icon} src="/images/jellyfin_logo.svg" alt="Jellyfin" />
        </Tooltip>
        <Typography variant="caption" >
          {formatQuantity(props.movieCount, 'movie')}
        </Typography>
        <Typography variant="caption">
          {formatQuantity(props.showCount, 'show') + ' ' + formatQuantity(props.episodeCount, 'episode')}
        </Typography>
        <Typography variant="caption">
          {formatDuration(props.runtime)}
        </Typography>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
MediaLibraryInfo.propTypes = {
  time: PropTypes.instanceOf(Date),
  movieCount: PropTypes.number,
  showCount: PropTypes.number,
  episodeCount: PropTypes.number,
  runtime: PropTypes.number
}

export default MediaLibraryInfo
