import { CardContent, Tooltip, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import formatAgo from '../../utils/ago'
import BikingIcon from '../Icons/Biking'
import CarSideIcon from '../Icons/CarSide'
import LaptopCodeIcon from '../Icons/LaptopCode'
import MugHotIcon from '../Icons/MugHot'
import RunningIcon from '../Icons/Running'
import SnoozeIcon from '../Icons/Snooze'
import WalkingIcon from '../Icons/Walking'
import LoadingCard from '../LoadingCard'

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    alignSelf: 'start'
  },
  icon: {
    fontSize: theme.typography.pxToRem(40)
  }
}))

const icons = {
  0: CarSideIcon,
  3: LaptopCodeIcon,
  1: BikingIcon,
  7: WalkingIcon,
  8: RunningIcon,
  72: SnoozeIcon,
  108: RunningIcon,
  109: SnoozeIcon,
  110: SnoozeIcon,
  112: MugHotIcon
}

const labels = {
  0: 'Driving',
  3: 'Chilling',
  1: 'Biking',
  7: 'Walking',
  8: 'Running',
  72: 'Sleeping',
  108: 'Sport',
  109: 'Sleeping',
  110: 'Sleeping',
  112: 'Awake'
}

const Activity = props => {
  const classes = useStyles()

  if (props.time == null || props.label == null || props.type == null) {
    return (
      <LoadingCard />
    )
  }

  const Icon = icons[`${props.type}`]
  const label = labels[`${props.type}`]

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} variant="subtitle2" color="textSecondary" gutterBottom>
          Activity
        </Typography>
        { Icon && (
          <Tooltip title={label ?? props.label} enterDelay={300} arrow placement="top">
            <div>
              <Icon className={classes.icon} />
            </div>
          </Tooltip>
        )}
        <Typography variant="h6">
          {label ?? props.label}
        </Typography>
        <Typography variant="overline" style={{ fontSize: 10 }} color="textSecondary">
          {formatAgo(props.time)}
        </Typography>
      </CardContent>
    </Card>
  )
}
Activity.propTypes = {
  time: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  type: PropTypes.number
}

export default Activity
