import SvgIcon from '@material-ui/core/SvgIcon'
import React from 'react'

function CarSide (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 640 512">
      <path d="M544 192h-16L419.21 56.02A63.99 63.99 0 0 0 369.24 32H155.33c-26.17 0-49.7 15.93-59.42 40.23L48 192v2.26C20.44 201.4 0 226.21 0 256v112c0 8.84 7.16 16 16 16h48c0 53.02 42.98 96 96 96s96-42.98 96-96h128c0 53.02 42.98 96 96 96s96-42.98 96-96h48c8.84 0 16-7.16 16-16v-80c0-53.02-42.98-96-96-96zM280 80h89.24c4.89 0 9.44 2.19 12.49 6l84.8 106H280V80zM140.47 90.06c2.45-6.11 8.28-10.06 14.86-10.06H232v112H99.7l40.77-101.94zM160 432c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm320 0c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm112-96h-29.31c-16.63-28.57-47.24-48-82.69-48s-66.05 19.43-82.69 48H242.69c-16.63-28.57-47.24-48-82.69-48s-66.05 19.43-82.69 48H48v-80c0-8.82 7.18-16 16-16h480c26.47 0 48 21.53 48 48v48z"/>
    </SvgIcon>
  )
}

export default CarSide
